<template>
  <div class="articleList">
    <a-button @click="$router.back()" icon="left">返回</a-button>
    <a-card :bordered="false" class="gmv-10">
      <div class="g-flex g-justify-space-between g-align-center">
        <div>
          <div class="gfs-18 gfw-b gmb-10" style="color: rgb(24, 144, 255);">{{articleDetail.name}}</div>
          <div class="gc-888">更新时间：{{articleDetail.update_date}}</div>
        </div>
        <div>
          共{{articleDetail.important_word_count}}篇
        </div>
      </div>
    </a-card>
    <a-card :bordered="false">
      <div slot="title">
        <div class="g-flex gpv-10 gph-10 g-flex gfs-14">
          <div class="gpv-5 gph-5" style="width:53px">题型：</div>
          <div class="g-flex g-wrap g-flex-1">
            <div v-for="(item,index) in typeList" :key="index" class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.question_type_id==item.id}" @click="changeType(item)">
              {{item.name}}
            </div>
          </div>
        </div>
        <div class="g-flex gpv-10 gph-10 g-flex gfs-14">
          <div class="gpv-5 gph-5" style="width:53px">分类：</div>
          <div class="g-flex g-wrap g-flex-1">
            <div v-for="(item,index) in sortList" :key="index" class="gpv-5 gph-5 g-pointer gmr-10" :class="{active:param.classify_id==item.id}" @click="changeSort(item)">
              {{item.name}}
            </div>
          </div>
        </div>
      </div>
      <div class="g-flex g-wrap">
        <div @click="toDetail(item)" class="gw-400p gmr-20 gmb-20 gbr-4 gbd gpv-10 gph-10 g-pointer" v-for="(item,index) in tableData" :key="index">
          <div class="g-flex g-justify-space-between gmb-10">
            <div>
              <span>{{subjectList[item.subject_type-1]}}</span>
              <span>·{{item.question_type_name}}</span>
              <span>·{{item.classify_name}}</span>
            </div>
            <div class="gc-aaa">{{item.update_date}}</div>
          </div>
          <div class="g-flex g-justify-space-between">
            <div class="g-flex-1 g-flex g-flex-column g-justify-space-between gmr-10">
              <div class="gfw-b gfs-18">{{item.name}}</div>
              <div>
                <div v-if="item.type==1" style="background:#1890ff;" class="gpv-2 gph-5 gc-fff gdp-ib">已斩</div>
                <div v-else class="gpv-2 gph-5 gdp-ib gbd">未斩</div>
              </div>
            </div>
            <img v-if="item.cover_img" :src="qiniuDomain+item.cover_img" class="gw-120p gh-120p">
            <img v-else src="@/assets/imgs/img4.png" class="gw-120p gh-120p">
          </div>
        </div>
      </div>
      <div class="gtr">
        <a-pagination v-model="param.page" :total="total" show-less-items @change="getData" />
      </div>
    </a-card>

  </div>
</template>
<script>
import {
  postImportantPage,
  getArticlePaperTypeList,
  getArticlePaperSortList,
  getArticleDetail,
} from "@/api/articlePaper";
import { subjectList } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      subjectList: JSON.parse(JSON.stringify(subjectList)),
      param: {
        question_type_id: "",
        classify_id: "",
        important_word_name: "",
        page: 1,
        limit: 10,
      },
      tableData: [],
      total: 0,
      typeList: [],
      sortList: [],
      articleDetail: {},
    };
  },
  computed: {
    ...mapGetters(["qiniuDomain"]),
  },
  created() {
    this.id = this.$route.query.id;
    this.getArticleDetail();
    this.getData();
    this.getArticlePaperTypeList();
    this.getArticlePaperSortList();
  },
  methods: {
    getData() {
      postImportantPage({
        ...this.param,
        important_word_paper_id: this.id,
      }).then((res) => {
        if (res.code == 200) {
          this.tableData = res.data;
          this.total = res.total;
        }
      });
    },
    toDetail(item) {
      this.$router.push({
        path: "/article/detail",
        query: {
          paperId: this.id,
          id: item.id,
        },
      });
    },
    //题型下拉列表
    getArticlePaperTypeList() {
      getArticlePaperTypeList({ importantWordPaperId: this.id }).then((res) => {
        if (res.code == 200) {
          this.typeList = [
            {
              id: "",
              name: "全部",
            },
            ...res.data,
          ];
        }
      });
    },
    //题型过滤
    changeType(item) {
      this.param.question_type_id = item.id;
      this.param.classify_id = "";
      this.getArticlePaperSortList();
      this.getData();
    },
    //分类下拉列表
    getArticlePaperSortList() {
      getArticlePaperSortList({
        importantWordPaperId: this.id,
        questionTypeId: this.param.question_type_id,
      }).then((res) => {
        if (res.code == 200) {
          this.sortList = [
            {
              id: "",
              name: "全部",
            },
            ...res.data,
          ];
        }
      });
    },
    //分类过滤
    changeSort(item) {
      this.param.classify_id = item.id;
      this.param.page = 1;
      this.getData();
    },
    //详情
    getArticleDetail() {
      getArticleDetail(this.id).then((res) => {
        if (res.code == 200) {
          this.articleDetail = res.data;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.articleList {
  .active {
    color: #fff;
    background: #1890ff;
  }
}
</style>